/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import ogimage from "../../images/ogimage.jpg";

type Tprops = {
	lang?: string;
	meta?: Object[];
	title?: string;
};

type HeaderData = {
	site: {
		siteMetadata?: {
			title: string;
			description: string;
			author: string;
			url: string;
			robots: string;
		};
	};
};

const SEO: React.FC<Tprops> = ({ lang = "en", meta = [], title }: Tprops) => {
	const { site }: HeaderData = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
						url
						robots
					}
				}
			}
		`
	);

	const metaDescription = site.siteMetadata?.description;
	const metaAuthor = site.siteMetadata?.author;
	const metaTitle = title || site.siteMetadata?.title;
	const metaUrl = site.siteMetadata?.url || "";
	const metaRobots = site.siteMetadata?.robots || "";

	const metaImage = `${metaUrl}${ogimage}`;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={metaTitle}
			titleTemplate={metaTitle}
			meta={(
				[
					{
						name: "description",
						content: metaDescription,
					},
					{
						name: "robots",
						content: metaRobots,
					},
					{
						property: "og:url",
						content: metaUrl,
					},
					{
						property: "og:site_name",
						content: metaAuthor,
					},
					{
						property: "og:title",
						content: metaTitle,
					},
					{
						property: "og:description",
						content: metaDescription,
					},
					{
						property: "og:type",
						content: "website",
					},
					{
						property: "og:image",
						content: metaImage,
					},
					{
						name: "twitter:url",
						content: metaUrl,
					},
					{
						name: "twitter:card",
						content: "summary_large_image",
					},
					{
						name: "twitter:title",
						content: metaTitle,
					},
					{
						name: "twitter:description",
						content: metaDescription,
					},
					{
						name: "twitter:image",
						content: metaImage,
					},
				] as object[]
			).concat(meta)}
		/>
	);
};

export default SEO;
