import * as React from "react";
import { ThemeProvider } from "emotion-theming";
import { Global, css } from "@emotion/core";
import { themes } from "@graphite/uneon";
import TagManager from "react-gtm-module";
import firebase from "gatsby-plugin-firebase";

import "normalize.css";
import "../../css/typography.css";

type TProps = {
	children: React.ReactNode;
};

const Wrapper: React.FC<TProps> = ({ children }: TProps) => {
	// Analitycs
	React.useEffect(() => {
		(async () => {
			try {
				const remoteConfig = firebase.remoteConfig();
				await remoteConfig.fetchAndActivate();

				const gtmId = remoteConfig.getString("gtmId");

				if (gtmId)
					TagManager.initialize({
						gtmId,
					});
			} catch (e) {
				//
			}
		})();
	}, []);

	return (
		<>
			<Global
				styles={css`
					body {
						background: #fff;
						overflow-x: hidden;
					}
				`}
			/>
			{children}
		</>
	);
};

const Layout: React.FC<TProps> = ({ children }: TProps) => (
	<ThemeProvider theme={themes.light}>
		<Wrapper>{children}</Wrapper>
	</ThemeProvider>
);

export default Layout;
